<template>
  <div id="selfie">
    <v-app-bar>
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-card-title class="title font-weight-medium block"
        >Ambil Foto Selfie</v-card-title
      >
    </v-app-bar>
    <camera />
  </div>
</template>
<script>
import Camera from "../components/camera/camera.component.vue";
export default {
  components: {
    Camera,
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
